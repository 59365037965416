import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroArea from '../components/hero-area'
import Card from '../components/card'
import styled from 'styled-components'
import FeaturedItems from '../components/featured-items'
import { Star } from 'react-feather'
import Button from '../components/button'
import ReviewsSlider from '../components/reviews-slider'
import PhotoGallery from '../components/photo-gallery'

class IndexPage extends React.Component {
  render() {
    const homePageNode = this.props.data.allContentfulPage.edges[0].node

    const MainContentContainer = styled.div``

    const SidebarContainer = styled.div``

    const SidebarColumn = () => {
      const sideBarCards = homePageNode.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }

    const ReviewsContainer = styled.div`
      padding: 3em 0;
      width: 100%;
    `

    const MissionStatement = styled.div`
      text-align: center;
      background: #fff;
      padding: 3em 2em;
      .container {
        max-width: 767px;
      }
      h2 {
        font-size: calc(1vw + 1em);
      }
      p {
        color: #555;
      }
    `
    const HeroRatingStars = styled.div`
      svg {
        fill: #ffbc00;
        stroke: none;
        height: calc(1.5vw + 1em);
        width: calc(1.5vw + 1em);
      }
    `
    const WhatCustomerAreSayingHeading = styled.h2`
      text-align: center;
      padding-bottom: 1em;
      font-size: calc(1vw + 1em);
    `

    const GalleryHomePage = styled.div`
      padding: 2.5em 0;
      background: white;
      margin-bottom: 2em;
      text-align: center;
      h2 {
        margin-bottom: 0.35em;
        font-size: calc(0.75vw + 1.2em);
      }
      p {
        max-width: 767px;
        color: #555;
        margin: 0 auto;
        margin-bottom: 1.5em;
      }
      img:nth-child(15) {
        display: none;
      }
    `
    return (
      <Layout transparentHeader={true}>
        <SEO
          title={homePageNode.metaTitle}
          description={homePageNode.metaDescription}
          keywords={['landscaping', 'calis finest landscaping', 'sacramento']}
        />
        {homePageNode.heroArea ? (
          <HeroArea heroContent={homePageNode.heroArea} />
        ) : (
          ''
        )}

        {homePageNode.featuredItems ? (
          <FeaturedItems data={homePageNode.featuredItems} />
        ) : (
          ''
        )}

        <GalleryHomePage>
          <PhotoGallery />
          <Button
            to={'/gallery/recent-work'}
            color={'light mt-3'}
            hasArrow={true}
          >
            View More
          </Button>
        </GalleryHomePage>

        <div className="container main-content">
          <div className="row">
            {/* <MainContentContainer
              className={homePageNode.sidebar ? 'col-12 col-md-8' : 'col-12'}
              dangerouslySetInnerHTML={{ __html: homePageNode.body.body }}
            /> */}
            {/* {homePageNode.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                <SidebarColumn />
              </SidebarContainer>
            ) : (
              ''
            )} */}
            <ReviewsContainer>
              <WhatCustomerAreSayingHeading>
                What Customers are Saying
              </WhatCustomerAreSayingHeading>
              <ReviewsSlider />
            </ReviewsContainer>
          </div>
        </div>
        <MissionStatement>
          <div className="container">
            <HeroRatingStars>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </HeroRatingStars>
            <h2 className="callout-text-color-brand-primary">
              Landscaping to Amaze and Delight You
            </h2>
            <p>
              We take pride in delivering exceptional results. As the highest
              rated landscaper in the Sacramento area, you can rely on us to
              provide you with timely, professional service. For all things
              landscaping in Sacramento, turn to the experts - contact us today
              for a free quote!
            </p>
            <Button to={'/about'} color={'light'} hasArrow={true}>
              Learn More About Us
            </Button>
          </div>
        </MissionStatement>
      </Layout>
    )
  }
}
class ComingSoon extends React.Component {
  render() {
    return <h1>Coming soon</h1>
  }
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          featuredItems {
            id
            serviceTitle
            excerpt
            slug
            heroImage {
              fluid(maxWidth: 370) {
                ...GatsbyContentfulFluid
              }
            }
          }
          id
          slug
          pageTitle
          metaTitle
          metaDescription
          body {
            body
          }
          heroArea {
            heroTitle
            description {
              description
            }
            heroStyle
            heroBackground
            customBgColor
            textColor
            primaryActionBtnLabel
            primaryActionBtnLink
            secondaryActionBtnLabel
            secondaryActionBtnLink
            heroImage {
              fluid(maxWidth: 1500) {
                ...GatsbyContentfulFluid
              }
            }
          }
          sidebar {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
  }
`
