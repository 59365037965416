import React from 'react'
import styled from 'styled-components'

const PhotoGallerySection = styled.div`
  text-align: center;
`
const GalleryHeading = styled.h2`
  margin-bottom: 0.35em;
  font-size: calc(0.75vw + 1.2em);
`
const GalleryDescription = styled.p`
  max-width: 767px;
  color: #555;
  margin: 0 auto;
  margin-bottom: 1.5em;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ImageWrapper = styled.div`
  flex: 0 0 12.5%;
  padding: 7px;
  img {
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
    border-radius: 2px;
  }
  @media (max-width: 992px) {
    flex: 0 0 33.333333333%;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
  }
  img {
    margin: 0;
  }
  &:nth-child(odd) {
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const PhotoGalleryComponent = ({ gallery }) => {
  let galleryAll = gallery
  if (gallery.length > 0) {
    galleryAll = gallery[0]
  }

  return (
    <>
      <PhotoGallerySection className="container">
        <GalleryHeading>
          {galleryAll.title ? galleryAll.title : 'No Gallery Title'}
        </GalleryHeading>
        {galleryAll.description && galleryAll.description.description ? (
          <GalleryDescription>
            {galleryAll.description.description}
          </GalleryDescription>
        ) : null}

        <FlexContainer>
          {galleryAll.photos.map((item, i) => (
            <ImageWrapper id={item.id} key={i}>
              <img src={item.fixed.src} />
            </ImageWrapper>
          ))}
        </FlexContainer>
      </PhotoGallerySection>
    </>
  )
}

export default PhotoGalleryComponent
