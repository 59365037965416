import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import PhotoGalleryComponent from '../components/photo-gallery-component'

const PhotoGallerySection = styled.section``

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ImageWrapper = styled.div`
  flex: 0 0 12.5%;
  padding: 5px;
  img {
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
    border-radius: 2px;
  }
  @media (max-width: 992px) {
    flex: 0 0 33.333333333%;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
  }
`
const PhotoGallery = () => (
  <StaticQuery
    query={graphql`
      query Photos {
        allContentfulPhotoGallery(
          filter: { contentful_id: { eq: "aWbscXKXoKnmYel4ghH1n" } }
        ) {
          edges {
            node {
              id
              title
              description {
                description
              }
              photos {
                id
                fixed(width: 300, height: 250) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      if (
        data.allContentfulPhotoGallery &&
        data.allContentfulPhotoGallery.edges[0] &&
        data.allContentfulPhotoGallery.edges[0].node
      ) {
        return (
          <>
            <PhotoGalleryComponent
              gallery={
                data.allContentfulPhotoGallery &&
                data.allContentfulPhotoGallery.edges[0] &&
                data.allContentfulPhotoGallery.edges[0].node
              }
            />
          </>
        )
      } else return null
    }}
  />
)

export default PhotoGallery
