import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Button from './button'

const ContentContainer = styled.div``

const ContentRow = styled.div``
const FeaturedItemsOuterWrapper = styled.section`
  padding: 40px 0;
`

const OneColumn = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 1em;
  }
  display: flex;
  padding: 0 8px !important;
`
const ColumnInnerContainer = styled.div`
  background: #fff;
`
const ColumnBody = styled.div`
  padding: 1.25em;
`
const Excerpt = styled.p`
  color: #666;
  font-size: 16px;
`
const Heading = styled.h4`
  margin-bottom: 0.5em;
  font-size: 1em;
  line-height: 1.3;
`

const FeaturedColumns = data => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} item={item} columnsCount={data.data.length} />
  ))
  return dataColumnsCount
}

const FeaturedColumn = data => {
  return (
    <OneColumn
      className={
        'feature-extended-image has-animation col-12 col-md-' +
        24 / data.columnsCount +
        ' col-lg-' +
        12 / data.columnsCount
      }
    >
      <ColumnInnerContainer className={'shadow-md border-radius'}>
        <Img
          fluid={data.item.heroImage.fluid}
          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
        />
        <ColumnBody
          className={'bt-color-secondary'}>
          <Heading>{data.item.serviceTitle}</Heading>
          <Excerpt>{data.item && data.item.excerpt}</Excerpt>

          <Button
            to={'/' + data.item && data.item.slug}
            color={'light'}
            text={'secondary'}
            title={'Learn more about ' + data.item.serviceTitle}
          >
            Learn More
          </Button>
        </ColumnBody>
      </ColumnInnerContainer>
    </OneColumn>
  )
}

const FeaturedItems = ({ data }) => {
  return (
    <FeaturedItemsOuterWrapper>
      <ContentContainer className="container">
        <ContentRow className="row">
          <FeaturedColumns data={data} />
        </ContentRow>
      </ContentContainer>
    </FeaturedItemsOuterWrapper>
  )
}
export default FeaturedItems
