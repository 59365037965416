import React from 'react'
import styled from 'styled-components'
import bbb from '../images/logos/bbb.png'
import google from '../images/logos/google.png'
import yelp from '../images/logos/yelp.png'
import fb from '../images/logos/fb.png'
import angies from '../images/logos/angies.png'
import porch from '../images/logos/porch.png'
import homeadvisor from '../images/logos/homeadvisor.png'

import CardGeneral from './card-general'

export default class Reputation extends React.Component {
  render() {
    let { props } = this

    const Reputation = styled.div``
    const CardHeader = styled.h3`
      margin: 0;
    `
    const CardHeaderContainer = styled.div`
      background: #f7f7f7;
      padding: 1em;
      margin: 0;
      border-radius: 0.25em 0.25em 0 0;
    `
    const ReviewsGrid = styled.div`
      display: flex;
      flex-wrap: wrap;
    `
    const ReviewItem = styled.div`
      padding: 0.5em 1.25em;
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-box-pack: end;
      justify-content: flex-end;
      img {
        margin-bottom: 5px;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding: 0;
      }
    `
    const ReviewItemDetails = styled.div`
      h4,
      p {
        margin: 0;
        text-align: center;
      }
      h4 {
        margin-top: 0.75em;
        font-size: 1.1em;
      }
      p {
        font-size: 0.65em;
        line-height: 1.1;
        padding-top: 0.25em;
      }
    `

    return (
      <Reputation>
        <CardGeneral title={'Find Us On'}>
          <ReviewsGrid>
            <ReviewItem>
              <img src={yelp} alt="Yelp 5 Stars" />
              <ReviewItemDetails>
                <h4>5/5</h4>
              </ReviewItemDetails>
            </ReviewItem>

            <ReviewItem>
              <img src={google} alt="Google 5 Stars" />
              <ReviewItemDetails>
                <h4>4.9/5</h4>
              </ReviewItemDetails>
            </ReviewItem>

            {/* <ReviewItem>
              <img src={bbb} alt="BBB A+ Rating" />
              <ReviewItemDetails>
                <h4>A+</h4>
                <p>11 Reviews</p>
              </ReviewItemDetails>
            </ReviewItem> */}

            <ReviewItem>
              <img src={fb} alt="Facebook 5 Stars" />
              <ReviewItemDetails>
                <h4>5/5</h4>
              </ReviewItemDetails>
            </ReviewItem>

            {/* <ReviewItem>
              <img src={angies} alt="Angies List 5 Stars" />
              <ReviewItemDetails>
                <h4>5/5</h4>
              </ReviewItemDetails>
            </ReviewItem> */}

            <ReviewItem>
              <img src={homeadvisor} alt="Home Advisor 5 Stars" />
              <ReviewItemDetails>
                <h4>5/5</h4>
              </ReviewItemDetails>
            </ReviewItem>

            {/* <ReviewItem><img src={porch} alt="Porch 5 Starts"/>
                    <ReviewItemDetails><h4>5/5</h4><p>15+ Reviews</p></ReviewItemDetails>
                    </ReviewItem> */}
          </ReviewsGrid>
        </CardGeneral>
      </Reputation>
    )
  }
}
