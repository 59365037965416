import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import * as Markdown from 'react-markdown'
import colors from '../styles/colors'
import Button from './button'

import Usa from '../assets/icons/usa'
import Hardhat from '../assets/icons/hard-hat'
import Boxusd from '../assets/icons/boxusd'
import { ArrowRight } from 'react-feather'

const HeroArea = ({ heroContent }) => {
  const ContentContainer = styled.div``

  const ContentRow = styled.div``
  const ContentCol = styled.div`
    text-align: center;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-right: 45px;
    color: ${heroContent.textColor == 'Light' ? '#fff;' : '#222'};
    text-shadow: ${heroContent.textColor == 'Light'
      ? '0 2px 2px rgba(0,0,0,.15);'
      : 'none'};
  `

  const HeroSection = styled.section`
    height: auto;
    background-size: cover;
    position: relative;

    .gatsby-image-wrapper {
      &:before {
        content: '';
        position: absolute;
        height: 104%;
        width: 100%;
        background: linear-gradient(
          rgba(0, 0, 0, 0.25),
          ${heroContent.heroBackground == 'Dark Overlay'
            ? 'rgba(0, 0, 0, 0.8)'
            : heroContent.heroBackground == 'Brand Color'
            ? colors.primary
            : heroContent.heroBackground == 'Custom Color'
            ? heroContent.customBgColor
            : '#f6f6f6'}
        );

        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.7;
      }
    }
  `
  const HeroHeading = styled.h1`
    position: relative;
    font-size: calc(1.5vw + 1.15em);
  `

  const FeaturedIconsContainer = styled.div`
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.25);
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-wrap: wrap;
    }
  `

  const IconDetails = styled.div`
    border-right: 1px solid hsla(0, 0%, 100%, 0.25);
    padding-right: 15px;
    padding-left: 15px;
    flex-grow: 1;
    flex-basis: 0;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: none;
      padding-right: 0;
    }
    position: relative;
    display: flex;

    svg {
      position: absolute;
      width: 30px;
    }
    @media (max-width: 576px) {
      flex: 0 0 100%;
      border: none;
      padding: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `

  const IconDetailsDetails = styled.div`
    padding-left: 45px;

    h4 {
      margin-bottom: 0.15em;
      letter-spacing: 0.2px;
      font-size: 20px;
      font-weight: 600;
    }
    p {
      margin-bottom: 0.05em;
      font-size: 13px;
      letter-spacing: 0.2px;
    }
  `

  const FeaturedIconsSection = () => {
    return (
      <FeaturedIconsContainer className="svg-color-secondary-lightest feature-extended-image has-animation">
        <IconDetails>
          <Usa />
          <IconDetailsDetails>
            <h4 className="color-secondary-lightest">100%</h4>
            <p>Family Owned &amp; Operated</p>
          </IconDetailsDetails>
        </IconDetails>
        <IconDetails>
          <Boxusd />
          <IconDetailsDetails>
            <h4 className="color-secondary-lightest">0%</h4>
            <p>Financing Available</p>
          </IconDetailsDetails>
        </IconDetails>
        <IconDetails>
          <Hardhat className="color" />
          <IconDetailsDetails>
            <h4 className="color-secondary-lightest">Lifetime</h4>
            <p>Manufacturer's Warranty</p>
          </IconDetailsDetails>
        </IconDetails>
      </FeaturedIconsContainer>
    )
  }

  return (
    <HeroSection className={'hero-' + heroContent.heroStyle}>
      <Img fluid={heroContent.heroImage.fluid} className="heroAreaImg" />
      <ContentContainer className="container">
        <ContentRow className="row">
          <div className="col-none col-lg-3" />
          <ContentCol className="col-md-9 col-lg-6">
            <HeroHeading className="before:background-secondary feature-extended-image has-animation">
              {heroContent.heroTitle}
            </HeroHeading>
            {heroContent.description.description ? (
              <Markdown
                className="feature-extended-image has-animation"
                source={heroContent.description.description}
              />
            ) : (
              ''
            )}
            {heroContent && heroContent.primaryActionBtnLink ? (
              <Button
                to={heroContent && heroContent.primaryActionBtnLink}
                color={'primary'}
                className={'feature-extended-image has-animation'}
                hasArrow={true}
              >
                {heroContent && heroContent.primaryActionBtnLabel}
              </Button>
            ) : null}
          </ContentCol>
        </ContentRow>
      </ContentContainer>
    </HeroSection>
  )
}

export default HeroArea
